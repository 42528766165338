import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { inspLayoutConstants } from '../globals/inspConstants.js';

import InspHomepage from './InspHomepage/InspHomepage.js';

function InspIndexRedirectHandler({ isAuthorized }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthorized) {
      let nextPage;
      const nextPathFromSessionStorage = sessionStorage.getItem('goto-path');

      if (nextPathFromSessionStorage && nextPathFromSessionStorage !== '/login') {
        nextPage = nextPathFromSessionStorage;
      } else {
        nextPage = inspLayoutConstants.PATH_NAMES.START_PAGE;
      }

      if (nextPathFromSessionStorage) sessionStorage.removeItem('goto-path');

      if (nextPage.startsWith('http://') || nextPage.startsWith('https://')) {
        window.location.href = nextPage;
      } else {
        navigate(nextPage, { replace: true });
      }
    }
  }, [isAuthorized, navigate]);

  return <InspHomepage />;
}

InspIndexRedirectHandler.propTypes = {
  isAuthorized: PropTypes.bool.isRequired
};

export default connect(state => ({
  isAuthorized: !!state.identity.role
}))(InspIndexRedirectHandler);
