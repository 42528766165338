import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import userRoles from '../globals/userRoles';
import { isLoggedIn } from '../redux/selectors/authorization/user';
import LocationChangeListener from './locationChangeListener';
import AppLayout from './AppLayout/AppLayout';
import NotFoundView from '../views/errors/NotFoundView';
import InspIndexRedirectHandler from '../routes/inspIndexRedirectHandler.js';
// Auth pages
import RegisterPage from '../routes/Register/RegisterPage';
// Blank page
import BlankPage from '../routes/BlankPage/BlankPage';
import SupportArticlePage from '../routes/Support/SupportArticle/SupportArticlePage';
import LaunchTokenLogoutPage from '../routes/LaunchTokenLogoutPage/LaunchTokenLogoutPage';
import InspBooksPage from '../routes/InspBooksPage/InspBooksPage.js';
import InspEcommercePage from '../routes/InspEcommercePage/InspEcommercePage.js';

import protectedRoute from './protectedRoute';
import withAuthorization from '../authorization/withAuthorization';
import RequestPasswordReset from '../routes/RequestPasswordReset/RequestPasswordReset.js';
import ResetPassword from '../routes/ResetPassword/ResetPassword.js';

function RouteManager() {
  return (
    <BrowserRouter>
      <LocationChangeListener>
        <Routes>
          {/* Blank page for loading core assets only, used for OUP.VIEW.embed prior to .gotoPage(...) */}
          <Route path="blank" element={<BlankPage />} />

          {/* Logged out status page for LTI Tokens and Impersonation tokens */}
          <Route
            path="/logged-out"
            element={
              <AppLayout>
                <LaunchTokenLogoutPage />
              </AppLayout>
            }
          />

          <Route
            path="/"
            element={
              <AppLayout>
                <InspIndexRedirectHandler />
              </AppLayout>
            }
          />

          <Route
            path="/books"
            element={<AppLayout>{withAuthorization(InspBooksPage, isLoggedIn, '/register')}</AppLayout>}
          />

          <Route
            path="/view/:bookId"
            element={<AppLayout embedded>{withAuthorization(InspEcommercePage, isLoggedIn, '/register')}</AppLayout>}
          />

          <Route
            path="/register"
            element={<AppLayout>{protectedRoute(RegisterPage, false, Object.keys(userRoles), '/books')}</AppLayout>}
          />

          {/* Support pages */}
          <Route
            path="/support/articles/:pageName?"
            element={
              <AppLayout>
                <SupportArticlePage />
              </AppLayout>
            }
          />

          <Route
            path="/reset-password/:token"
            element={
              <AppLayout>
                <ResetPassword />
              </AppLayout>
            }
          />

          <Route
            path="/reset-password"
            element={
              <AppLayout>
                <RequestPasswordReset />
              </AppLayout>
            }
          />

          {/* 404 */}
          <Route
            path="*"
            element={
              <AppLayout>
                <NotFoundView />
              </AppLayout>
            }
          />
        </Routes>
      </LocationChangeListener>
    </BrowserRouter>
  );
}

export default RouteManager;
