import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
import styles from './inspEcommercePage.scss';
import { mode } from '../../redux/reducers/inspectionCopy/inspectionCopy.reducer.js';
import {
  getSubscriptionIdRequest,
  getSubscriptionReset,
  createUserActivityRequest
} from '../../redux/actions/inspectionCopy';
import { getBookTitleRequest } from '../../redux/actions/inspectionCopyBookTitle.js';

const checkSubscriptionExpiry = subscriptionDetails => {
  const expiryDateTime = subscriptionDetails.expiry;
  const expiryMinutes = moment(expiryDateTime).diff(moment(new Date()), 'minutes');
  return expiryMinutes <= 5;
};

function InspEcommercePage({
  selfSelectedRole,
  claimedSchool,
  countryCode,
  getSubscriptionIdRequestAction,
  getSubscriptionResetAction,
  createUserActivityAction,
  subscriptionDetails,
  bookTitleDetails,
  getBookTitleAction
}) {
  const { bookId } = useParams();

  useEffect(() => {
    if (
      bookId &&
      !bookTitleDetails.bookTitle &&
      bookTitleDetails.mode !== mode.LOADING &&
      bookTitleDetails.mode !== mode.ERROR
    ) {
      getBookTitleAction(bookId);
    }

    if (bookId && !subscriptionDetails.subid && subscriptionDetails.mode !== mode.LOADING) {
      getSubscriptionIdRequestAction();
    }

    if (
      bookId &&
      bookTitleDetails.bookTitle &&
      bookTitleDetails.mode !== mode.LOADING &&
      subscriptionDetails.subid &&
      subscriptionDetails.mode === mode.SUCCESS
    ) {
      if (checkSubscriptionExpiry(subscriptionDetails)) {
        getSubscriptionResetAction();
      } else {
        createUserActivityAction();
      }
    }
  }, [bookId, bookTitleDetails, subscriptionDetails]);

  const srcUrl = `https://v.calameo.com/?bkcode=${bookId}&langid=en&clickTo=embed&clickTarget=_blank&autoFlip=0&showArrows=1&page=1&subid=${subscriptionDetails.subid}&wmode=opaque&skinurl=`;
  return (
    <div className={styles.inspBookContainer}>
      <Helmet title={bookTitleDetails.bookTitle} titleTemplate={bookTitleDetails.bookTitle} />
      {/* WIP */}
      {selfSelectedRole && claimedSchool && countryCode && subscriptionDetails.subid && bookId && (
        <iframe title="myBook" width="100%" height="100%" src={srcUrl} />
      )}
    </div>
  );
}

InspEcommercePage.propTypes = {
  selfSelectedRole: PropTypes.string.isRequired,
  claimedSchool: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  subscriptionDetails: PropTypes.object,
  getSubscriptionIdRequestAction: PropTypes.func.isRequired,
  getSubscriptionResetAction: PropTypes.func.isRequired,
  bookTitleDetails: PropTypes.object,
  getBookTitleAction: PropTypes.func.isRequired,
  createUserActivityAction: PropTypes.func.isRequired
};

export default compose(
  connect(
    state => ({
      selfSelectedRole: state.identity.selfSelectedRole,
      claimedSchool: state.identity.claimedSchool,
      countryCode: state.identity.countryCode,
      subscriptionDetails: state.inspectionCopy || {},
      bookTitleDetails: state.inspectionCopyBookTitle || {}
    }),
    {
      getSubscriptionIdRequestAction: getSubscriptionIdRequest,
      getSubscriptionResetAction: getSubscriptionReset,
      getBookTitleAction: getBookTitleRequest,
      createUserActivityAction: createUserActivityRequest
    }
  )
)(InspEcommercePage);
